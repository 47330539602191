<template>
  <div>
    <rankingBanner></rankingBanner>
    <div class="content_area">
      <rankingNav
          class="marginT30"
          :navlist='navlist'
          :navlist_active='navlist_active'
          :loading="loading"
          @navEvents='navEvents'
      ></rankingNav>
    </div>
     <div class="containerBox">
      <div class="boxLeft bg3"></div>
      <div class="content_area itemBox">
        <div class="content">
          <div class="bgText bgTextColor3">Leaderboard</div>
          <div class="contentName contNameColor3" :class="LOCALE === 'zh'? '' : 'font17'">{{$t('logisticscompanies')}}</div>
          <div>
            <span class="btn" @click="handleBtn(3)">{{$t('CompleteList')}} ></span>
          </div>
        </div>
        <div class="boxRightList" v-loading="loading">
          <div v-if="dataList.logistics_company_ranking_list.length > 0" class="flexBox">
            <div class="boxRightItem" v-for="(item, index) in dataList.logistics_company_ranking_list" :key="index">
              <div class="rankingImg">
                <img v-show="index === 0" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/one.png" alt="">
                <img v-show="index === 1" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/two.png" alt="">
                <img v-show="index === 2" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/three.png" alt="">
              </div>
              <div class="rankingNumber">
                <span>{{$t('bh_Num')}}</span>
                <span>{{ item.number }}</span>
              </div>
              <div @click="handleCompany(item, 3)" class="point">
                <div class="companyLogoBox">
                  <el-image :src="item.logo ? item.logo : DEFAULT_LOGO" class="companyLogo" fit="scale-down"></el-image>
                </div>
                <div class="companyNameBox">
                  <div class="companyName">{{ item.company_name_en | priorFormat(item.company_name_zh, LOCALE) }}</div>
                </div>
                <div class="boothBox">
                  <span class="booth">{{$t('BoothNumber')}}：{{ item.booth_no }}</span>
                </div>
              </div>
              <div class="voteBox">
                <span class="vote">{{$t('voted')}}：</span>
                <span class="voteNumber">{{ item.votes }}</span>
              </div>
            </div>
          </div>
          <noDataImg v-if="!loading && dataList.logistics_company_ranking_list.length === 0"></noDataImg>
        </div>
      </div>
    </div>
    <div class="containerBox">
      <div class="boxLeft bg1"></div>
      <div class="content_area itemBox">
        <div class="content">
          <div class="bgText bgTextColor1">Leaderboard</div>
          <div class="contentName contNameColor1" :class="LOCALE === 'zh'? '' : 'font17'">{{$t('contributionenterprises')}}</div>
          <div>
            <span class="btn" @click="handleBtn(1)">{{$t('CompleteList')}} ></span>
          </div>
        </div>
        <div class="boxRightList" v-loading="loading">
          <div v-if="dataList.protest_company_ranking.length > 0" class="flexBox">
            <div class="boxRightItem" v-for="(item, index) in dataList.protest_company_ranking" :key="index">
              <div class="rankingImg">
                <img v-show="index === 0" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/one.png" alt="">
                <img v-show="index === 1" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/two.png" alt="">
                <img v-show="index === 2" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/three.png" alt="">
              </div>
              <div class="rankingNumber">
                <span>{{ $t('bh_Num') }}</span>
                <span>{{ item.number }}</span>
              </div>
              <div @click="handleCompany(item,1)" class="point">
                <div class="companyLogoBox">
                  <el-image :src="item.logo ? item.logo : DEFAULT_LOGO" class="companyLogo" fit="scale-down" ></el-image>
                </div>
                <div class="companyNameBox">
                  <div class="companyName">{{ item.company_name_en | priorFormat(item.company_name_zh, LOCALE) }}</div>
                </div>
                <div class="boothBox">
                  <span class="booth">{{$t('BoothNumber')}}：{{ item.booth_no }}</span>
                </div>
              </div>

              <div class="voteBox">
                <span class="vote">{{$t('voted')}}：</span>
                <span class="voteNumber">{{ item.votes }}</span>
              </div>
            </div>
          </div>
          <noDataImg v-if="!loading && dataList.protest_company_ranking.length === 0"></noDataImg>
        </div>
      </div>
    </div>
    <div class="containerBox">
      <div class="boxLeft bg2"></div>
      <div class="content_area itemBox">
        <div class="content">
          <div class="bgText bgTextColor2">Leaderboard</div>
          <div class="contentName contNameColor2" :class="LOCALE === 'zh'? '' : 'font17'">{{$t('epidemichero')}}</div>
          <div>
            <span class="btn" @click="handleBtn(2)">{{$t('CompleteList')}} ></span>
          </div>
        </div>
        <div class="boxRightList" v-loading="loading">
          <div v-if="dataList.protest_hero_ranking.length > 0" class="flexBox">
            <div class="boxRightItem" v-for="(item, index) in dataList.protest_hero_ranking" :key="index">
              <div class="rankingImg">
                <img v-show="index === 0" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/one.png" alt="">
                <img v-show="index === 1" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/two.png" alt="">
                <img v-show="index === 2" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/three.png" alt="">
              </div>
              <div class="rankingNumber">
                <span>{{$t('bh_Num')}}</span>
                <span>{{ item.number }}</span>
              </div>
              <div class="point" @click="handleCompany(item, 2)">
                <div class="companyLogoBox border50">
                  <el-image :src="item.avatar ? item.avatar : DEFAULT_AVATAR" class="companyLogo border50" fit="scale-down"></el-image>
                </div>
                <div class="nameBox">
                  <span class="name">{{ item.user_name_en | priorFormat(item.user_name_zh, LOCALE) }}</span>
                  <span class="job">{{ item.job_title_en | priorFormat(item.job_title_zh, LOCALE) }}</span>
                </div>
                <div class="userCompanyNameBox">
                  <div class="userCompanyName">{{ item.company_name_en | priorFormat(item.company_name_zh, LOCALE) }}</div>
                </div>
              </div>
              <div class="voteBox">
                <span class="vote">{{$t('voted')}}：</span>
                <span class="voteNumber">{{ item.votes }}</span>
              </div>
            </div>
          </div>
          <noDataImg v-if="!loading && dataList.protest_hero_ranking.length === 0"></noDataImg>
        </div>
      </div>
    </div>
   
    <div class="containerBox">
      <div class="boxLeft bg4"></div>
      <div class="content_area itemBox">
        <div class="content">
          <div class="bgText bgTextColor4">Leaderboard</div>
          <div class="contentName contNameColor4" :class="LOCALE === 'zh'? '' : 'font17'">{{$t('Logisticsbeauty')}}</div>
          <div>
            <span class="btn" @click="handleBtn(4)">{{$t('CompleteList')}} ></span>
          </div>
        </div>
        <div class="boxRightList" v-loading="loading">
          <div v-if="dataList.logistics_wowen_ranking.length > 0" class="flexBox">
           <div class="boxRightItem" v-for="(item, index) in dataList.logistics_wowen_ranking" :key="index">
            <div class="rankingImg">
              <img v-show="index === 0" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/one.png" alt="">
              <img v-show="index === 1" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/two.png" alt="">
              <img v-show="index === 2" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/three.png" alt="">
            </div>
            <div class="rankingNumber">
              <span>{{$t('bh_Num')}}</span>
              <span>{{ item.number }}</span>
            </div>
            <div class="point" @click="handleCompany(item, 4)">
              <div class="companyLogoBox border50">
                <el-image :src="item.avatar ? item.avatar : DEFAULT_AVATAR" class="companyLogo border50" fit="scale-down"></el-image>
              </div>
              <div class="nameBox">
                <span class="name">{{ item.user_name_en | priorFormat(item.user_name_zh, LOCALE) }}</span>
                <span class="job">{{ item.job_title_en | priorFormat(item.job_title_zh, LOCALE) }}</span>
              </div>
              <div class="userCompanyNameBox">
                <div class="userCompanyName">{{ item.company_name_en | priorFormat(item.company_name_zh, LOCALE) }}</div>
              </div>
            </div>
            <div class="voteBox">
              <span class="vote">{{$t('voted')}}：</span>
              <span class="voteNumber">{{ item.votes }}</span>
            </div>
          </div>
          </div>
          <noDataImg v-if="!loading && dataList.logistics_wowen_ranking.length === 0"></noDataImg>
        </div>
      </div>
    </div>
    <div class="containerBox">
      <div class="boxLeft bg5"></div>
      <div class="content_area itemBox">
        <div class="content">
          <div class="bgText bgTextColor5">Leaderboard</div>
          <div class="contentName contNameColor5" :class="LOCALE === 'zh'? '' : 'font17'">{{$t('Logisticsman')}}</div>
          <div>
            <span class="btn" @click="handleBtn(5)"> {{$t('CompleteList')}} ></span>
          </div>
        </div>
        <div class="boxRightList" v-loading="loading">
          <div v-if="dataList.logistics_men_ranking.length > 0" class="flexBox">
            <div class="boxRightItem" v-for="(item, index) in dataList.logistics_men_ranking" :key="index">
            <div class="rankingImg">
              <img v-show="index === 0" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/one.png" alt="">
              <img v-show="index === 1" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/two.png" alt="">
              <img v-show="index === 2" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/three.png" alt="">
            </div>
            <div class="rankingNumber">
              <span>{{$t('bh_Num')}}</span>
              <span>{{ item.number }}</span>
            </div>
            <div class="point" @click="handleCompany(item, 5)">
              <div class="companyLogoBox border50">
                <el-image :src="item.avatar ? item.avatar : DEFAULT_AVATAR" class="companyLogo border50" fit="scale-down"></el-image>
              </div>
              <div class="nameBox">
                <span class="name">{{ item.user_name_en | priorFormat(item.user_name_zh, LOCALE) }}</span>
                <span class="job">{{ item.job_title_en | priorFormat(item.job_title_zh, LOCALE) }}</span>
              </div>
              <div class="userCompanyNameBox">
                <div class="userCompanyName">{{ item.company_name_en | priorFormat(item.company_name_zh, LOCALE) }}</div>
              </div>
            </div>
            <div class="voteBox">
              <span class="vote">{{$t('voted')}}：</span>
              <span class="voteNumber">{{ item.votes }}</span>
            </div>
          </div>
          </div>
          <noDataImg v-if="!loading && dataList.logistics_men_ranking.length === 0"></noDataImg>
        </div>
      </div>
    </div>
    <dialogCompany></dialogCompany>
  </div>
</template>

<script>
  import rankingNav from '~exp/components/rankingList/rankingNav'
  import rankingBanner from '~exp/components/rankingList/banner'
  import dialogCompany from '~exp/components/rankingList/dialog_company'
  export default {
    name: "overallRanking",
    components:{
      rankingNav,
      rankingBanner,
      dialogCompany
    },
    data() {
      return {
        navlist_active: 1,
        navlist: [
          {
            lable: this.$t('listofWeek1'),
            id: 1,
          },
          {
            lable: this.$t('listofWeek2'),
            id: 2
          },
          {
            lable: this.$t('listofWeek3'),
            id: 3
          },
          {
            lable: this.$t('OverallList'),
            id: 4
          },
        ],
        dataList: {
          logistics_company_ranking_list: [],  // 物流企业
          logistics_men_ranking: [],    //  #物流型男
          logistics_wowen_ranking: [],    //物流女神
          protest_company_ranking: [],   //  抗疫企业
          protest_hero_ranking: []  //  抗疫英雄
        },
        loading: false
      }
    },
    mounted() {
      if (this.VTOE_TIME === 0) {
        this.navlist_active = 1
      } else {
        this.navlist_active = this.VTOE_TIME
      }
      this.getDataList()
      this.$GLOBALEVENT.$on("DIALOFGCOMPANYCLOSE", () => {
        this.getDataList()
      });
    },
    methods:{
      async getDataList() {
        this.loading = true
        // 当前时间是在第一周，并且点击了第二，三周或者总榜
        if (this.VTOE_TIME === 0 && this.navlist_active >0 || this.VTOE_TIME ===1 && this.navlist_active >1 || this.VTOE_TIME ===2 && this.navlist_active >2 || this.VTOE_TIME ===3 && this.navlist_active >3) {
          this.dataList = {
            logistics_company_ranking_list: [],
            logistics_men_ranking: [],
            logistics_wowen_ranking: [],
            protest_company_ranking: [],
            protest_hero_ranking: []
          }
          this.loading = false
          return
        }
        let params = {
          meeting_id: this.MEETING_ID,
          start: 0,
          type: 1,
          week: this.navlist_active,
          end: 2
        };
        if (this.navlist_active == 4) {
          params.week = ''
        }
        let res = await this.$store.dispatch("rankingList/getRankingList", params);
        this.loading = false
        if (res.success) {
          this.dataList = res.data
        }
      },
      navEvents(e){
        this.navlist_active = e.id
        this.getDataList()
      },
      handleBtn(val) {
        this.$router.push({
          path: "/overallRankingDetail",
          query: {
            parameter: this._encode({
              rank_id: val,
            }),
          },
        });
      },
      handleCompany(item, number) {
        //打开公司详情
        let type = {
          query: {
            type:"company",
            value: 1
          }
        }
        // 1抗议企业 2英雄   3人气企业， 4  女神， 5是男神

        if (number == 2 || number == 4 || number == 5) {
          type.query.type = 'people'
        }
        if (number == 1) {
          type.query.value = 2
        }
        if (number == 3) {
          type.query.value = 1
        }
        if (number == 4) {
          type.query.value = 3
        }
        if (number == 5) {
          type.query.value = 2
        }
        this.$GLOBALEVENT.$emit("OPENCOMPANY",{show:true, params: item, type})
      }
    },
    destroyed(){
      this.$GLOBALEVENT.$off("DIALOFGCOMPANYCLOSE")
    },
  }
</script>

<style scoped lang="less">
.marginT30 {
  margin-top: 30px;
}
.containerBox {
  position: relative;
  height: 331px;
  .itemBox {
    position: relative;
    display: flex;
    padding-top: 40px;
  }
  .boxLeft {
    width: 710px;
    height: 331px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .content {
    width: 245px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .bgText {
      position: absolute;
      top: 100px;
      left: 0;
      font-size: 38px;
      font-weight: 800;
      z-index: -1;
    }
    .contentName {
      font-size: 36px;
      padding-bottom: 10px;
      font-weight: 600;
    }
    .btn {
      font-size: 16px;
      color: #1F292E;
      cursor: pointer;
      position: relative;
      z-index: 15;
    }
  }
  .boxRightList {
    position: relative;
    display: flex;
    z-index: 11;
    height: 251px;
    width: 1055px;
    .boxRightItem {
      width: 319px;
      height: 251px;
      background: #FFFFFF;
      box-shadow: 0px 0px 16px 0px rgba(216, 232, 250, 0.39);
      border-radius: 10px;
      border: 1px solid #F2F2F3;
      margin-right: 49px;
      position: relative;
      padding-top: 28px;
      &:nth-child(3) {
        margin-right: 0px;
      }
      .rankingImg {
        position: absolute;
        top: 0;
        left: 10px;
        width: 45px;
        height: 77px;
        background: linear-gradient(180deg, #EDEDED 0%, rgba(255, 255, 255, 0) 100%);
        text-align: center;
        padding-top: 16px;
      }
      .rankingNumber {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 106px;
        padding: 4px 13px 4px 0;
        font-size: 12px;
        color: #FF9905;
        background: linear-gradient(270deg, #FFFADA 0%, rgba(255, 252, 231, 0) 100%);
        border-radius: 0px 10px 0px 0px;
        text-align: right;

      }
      .companyLogoBox {
        text-align: center;
        .companyLogo {
          width: 77px;
          height: 77px;
        }
      }
      .companyNameBox {
        text-align: center;
        margin-top: 12px;
        .companyName {
          width: 224px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          font-size: 16px;
          font-weight: 800;
          color: #333333;
          margin: 0 auto;
          cursor: pointer;
        }
      }
      .boothBox {
        margin-top: 8px;
        text-align: center;
        .booth {
          color: #DD9740;
          font-size: 14px;
        }
      }
      .voteBox {
        margin-top: 30px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        .vote {
          color: #7D8695;
        }
        .voteNumber {
          color: #027FFF;
        }
      }
      .nameBox {
        text-align: center;
        margin-top: 10px;
        .name {
          color: #333333;
          font-size: 18px;
          font-weight: 800;
          margin-right: 6px;
        }
        .job {
          font-size: 14px;
          color: #7D8695;
        }
      }
      .userCompanyNameBox {
        text-align: center;
        margin-top: 11px;
        .userCompanyName {
          color: #333333;
          width: 224px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          margin: 0 auto;
          cursor: pointer;
        }
      }
    }
  }
  .flexBox {
    display: flex;
  }
  .bg1 {
    background: linear-gradient(90deg, rgba(8, 109, 223, 0.28) 0%, rgba(216, 234, 255, 0) 100%);
  }
  .bg2 {
    background: linear-gradient(90deg, #FF9C91 0%, rgba(255, 221, 218, 0) 100%);
  }
  .bg3 {
    background: linear-gradient(90deg, #FFBE9C 0%, rgba(255, 228, 214, 0) 100%);
  }
  .bg4 {
    background: linear-gradient(90deg, #FF9EE6 0%, rgba(255, 229, 248, 0) 100%);
  }
  .bg5 {
    background: linear-gradient(90deg, #C7968A 0%, rgba(255, 224, 217, 0) 100%);
  }
  .bgTextColor1 {
    color: #D4E9FF;
  }
  .bgTextColor2 {
    color: #FFCDC8;
  }
  .bgTextColor3 {
    color: #FFD2BB;
  }
  .bgTextColor4 {
    color: #FFC3EF;
  }
  .bgTextColor5 {
    color: #E0BEB6;
  }
  .contNameColor1 {
    color: #027FFF;
  }
  .contNameColor2 {
    color: #FE4431;
  }
  .contNameColor3 {
    color: #FF7F3C;
  }
  .contNameColor4 {
    color: #F833C5;
  }
  .contNameColor5 {
    color: #9A4835;
  }
  @media screen and (max-width:1500px){
    .boxLeft {
      width: 400px;
    }
  }
}
.border50 {
  border-radius: 50%;
}
.font17 {
  font-size: 17px!important;
}
</style>